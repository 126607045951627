<template>
	<div class="monthIncomeSummary">
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<table>
				<tr>
					<th colspan="2">我的账户</th>
					<td style="display: flex;align-items: center;position: relative;">
						<span v-if="tableData.wallet.auto_withdraw == 0"
							style="color:red;font-size: 14px;">自动提现已关闭</span>
						<span v-else
							style="color:green;font-size: 14px;">自动T1提现已开启（留存{{ tableData.wallet.keep_money }}元）</span>
						<el-button type="warning" size="mini" @click.stop="handleAutoWithDraw"
							style="position: absolute;right: 10px;" v-if="tableData.bank_card">设置</el-button>
					</td>
				</tr>
				<tr>
					<td colspan="2">当前账户余额</td>
					<td style="display: flex;align-items: center;position: relative;">
						<span>{{ tableData.wallet.real_money }}</span>
						<el-button type="warning" size="mini" @click.stop="handleWithDraw"
							style="position: absolute;right: 10px;" v-if="tableData.bank_card">提现</el-button>
					</td>
				</tr>
				<tr>
					<td colspan="2">团长在途金额(未核销)</td>
					<td>{{ tableData.wallet.uncheck_money }} </td>
				</tr>
				<tr>
					<td colspan="2">团批在途金额(未核销)</td>
					<td>{{ tableData.wallet.uncheck_ls_money }} </td>
				</tr>
				<tr>
					<td colspan="2">安心采在途(待解冻)</td>
					<td>{{ tableData.wallet.freeze_money }} </td>
				</tr>
				<tr>
					<td colspan="2">开户行</td>
					<td>{{ tableData.bank_card ? tableData.bank_card.bank_name:"" }} </td>
				</tr>
				<tr>
					<td colspan="2">银行账号</td>
					<td style="display: flex;align-items: center;position: relative;">
						<span>{{ tableData.bank_card ? tableData.bank_card.card_number:"-" }}</span>
						<el-button type="warning" size="mini" @click.stop="handleAdd" style="position: absolute;right: 10px;"
							v-if="!tableData.bank_card">绑卡</el-button>
						<el-popconfirm title="确定要解绑银行卡吗？" @confirm="confirmDelete"
							style="position: absolute;right: 10px;" v-else>
							<el-button slot="reference" type="info" size="mini">解绑</el-button>
						</el-popconfirm>
					</td>
				</tr>
				<tr>
					<td colspan="2">持卡人姓名</td>
					<td>{{ tableData.bank_card ? tableData.bank_card.name:"" }}</td>
				</tr>
			</table>
		</div>
		<!-- 提现 -->
		<el-dialog title="设置自动提现(T1)" :visible.sync="autoWithDrawDialg" :close-on-click-modal="false" v-el-drag-dialog
			width="50%" v-loading="loading">
			<el-form ref="withdrawForm" :model="autoWithDrawForm" label-width="120px">
				<el-form-item label="留存金额" required>
					<el-input v-model="autoWithDrawForm.keep_money" placeholder="请输入留存金额"></el-input>
				</el-form-item>
				<el-form-item label="自动提现" required>
					<el-radio-group v-model="autoWithDrawForm.auto_withdraw">
						<el-radio :label="0">关闭</el-radio>
						<el-radio :label="1">开启</el-radio>
					</el-radio-group>
					<p>开启自动提现后，每日晚上11点自动发起T1提现，账户余额不足留存金额时不提现</p>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="autoWithDrawDialg = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmAutoWithDraw">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 提现 -->
		<el-dialog title="提现" :visible.sync="withDrawDialog" :close-on-click-modal="false" v-el-drag-dialog width="50%"
			v-loading="loading">
			<el-form ref="withdrawForm" :model="withDrawForm" label-width="120px">
				<el-form-item label="提现金额" required>
					<el-input v-model="withDrawForm.money" placeholder="请输入提现金额"></el-input>
				</el-form-item>
				<el-form-item label="提现方式" required>
					<el-radio-group v-model="withDrawForm.type">
						<el-radio :label="0">T+1到账（下一个工作日到账，零手续费）</el-radio>
						<el-radio :label="1">实时到账（需支付万分之五的手续费）</el-radio>
					</el-radio-group>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="withDrawDialog = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmWithDraw">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 新增 -->
		<el-dialog title="绑定银行卡" :visible.sync="addPersonDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="50%" v-loading="loading">
			<el-form ref="personForm" :model="addForm" label-width="120px">
				<el-form-item label="开户地区" required>
					<el-cascader ref="cascader" style="width:100%" v-model="addressVal" :props="{
					    value: 'code',
					    label: 'name'
					}" :options="addressList" @change="handleAddressChange"></el-cascader>
				</el-form-item>
				<el-form-item label="开户行" required>
					<el-select v-model="addForm.bank_code" filterable remote reserve-keyword placeholder="请输入银行关键词"
						:remote-method="remoteBank" :loading="loading" @change="changeBank">
						<el-option v-for="item in bankOptions" :key="item.bank_code" :label="item.bank_name"
							:value="item.bank_code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="银行账号" required>
					<el-input v-model="addForm.card_number" placeholder="请输入银行账号">
					</el-input>
				</el-form-item>
				<el-form-item label="持卡人姓名" required>
					<el-input v-model="addForm.name" placeholder="请输入持卡人姓名" readonly=""></el-input>
				</el-form-item>
				<el-form-item label="持卡人手机号" required>
					<el-input v-model="addForm.telephone" placeholder="请输入持卡人手机号"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="addPersonDialog = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmAddPerson">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 新增 -->
		<el-dialog title="绑定银行卡" :visible.sync="addEntryDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="50%" v-loading="loading">
			<el-form ref="entryFrom" :model="addForm" label-width="120px">
				<el-form-item label="持卡人类型" required>
					<el-radio-group v-model="addForm.card_type">
						<el-radio :label="0">对公</el-radio>
						<el-radio :label="1">对私法人</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开户地区" required>
					<el-cascader ref="cascader" style="width:100%" v-model="addressVal" :props="{
					    value: 'code',
					    label: 'name'
					}" :options="addressList" @change="handleAddressChange"></el-cascader>
				</el-form-item>
				<el-form-item label="开户行" required>
					<el-select v-model="addForm.bank_code" filterable remote reserve-keyword placeholder="请输入银行关键词"
						:remote-method="remoteBank" :loading="loading" @change="changeBank">
						<el-option v-for="item in bankOptions" :key="item.bank_code" :label="item.bank_name"
							:value="item.bank_code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开户支行" required>
					<el-select v-model="addForm.branch_code" filterable remote reserve-keyword placeholder="请输入银行支行关键词"
						:remote-method="remoteBranch" :loading="loading" @change="changeBranch">
						<el-option v-for="item in branchOptions" :key="item.branch_code" :label="item.branch_name"
							:value="item.branch_code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="银行账号" required>
					<el-input v-model="addForm.card_number" placeholder="请输入银行账号">
					</el-input>
				</el-form-item>
				<el-form-item label="持卡人姓名" required>
					<el-input v-model="addForm.name" placeholder="请输入持卡人姓名"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="addEntryDialog = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmAddEntry">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: true,
				tableData: {
					wallet: {
						real_money: 0,
						uncheck_money: 0,
						uncheck_ls_money: 0,
						freeze_money: 0,
						keep_money: 0,
						auto_withdraw: 0
					},
					bankcard: null
				},
				withDrawDialog: false,
				addPersonDialog: false,
				addEntryDialog: false,
				withDrawForm: {
					money: 0,
					type: 0
				},
				addForm: {
					card_type: 0,
					name: ""
				},
				addressVal: [],
				addressList: [],
				branchOptions: [],
				bankOptions: [],
				autoWithDrawForm: {},
				autoWithDrawDialg: false

			}
		},
		mounted() {
			// 列表
			this.getList()
			this.getAddressList()
		},
		methods: {
			handleAutoWithDraw() {
				this.autoWithDrawForm = {
					...this.tableData.wallet
				}
				this.autoWithDrawDialg = true
			},
			confirmAutoWithDraw() {
				this.loading = true;
				this.$http.post(`erp/v1/wallet/autoWithDraw`, this.autoWithDrawForm).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.autoWithDrawDialg = false
					this.loading = false
					if (code == 200) {
						this.getList()
					} else {
						this.$message.error(msg)
					}
				});

			},
			changeBank(e) {
				this.addForm.bank_code = e
			},
			changeBranch(e) {
				this.addForm.branch_code = e
			},
			remoteBank(query) {
				if (query !== '') {
					this.loading = true;
					this.$http.post(`erp/v1/common/bankLst`, {
						keyword: query
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						this.loading = false
						if (code == 200) {
							this.bankOptions = data
						} else {
							this.$message.error(msg)
						}
					});

				} else {
					this.bankOptions = [];
				}
			},
			remoteBranch(query) {
				if (query !== '') {
					this.loading = true;
					this.$http.post(`erp/v1/common/bankBranch`, {
						bank_code: this.addForm.bank_code,
						keyword: query
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						this.loading = false
						if (code == 200) {
							this.branchOptions = data
						} else {
							this.$message.error(msg)
						}
					});

				} else {
					this.branchOptions = [];
				}
			},
			getAddressList() {
				this.$http.post(`erp/v1/common/all_district`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.addressList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择省市区
			handleAddressChange(e) {
				this.addForm.prov_code = e[0]
				this.addForm.city_code = e[1]
				this.addForm.area_code = e[2]
			},
			handleAdd() {
				this.addForm.name = this.tableData.cert_name
				if (this.tableData.cert_type == 1) {
					this.addPersonDialog = true;
				} else {
					this.addForm.card_type = 0
					this.addEntryDialog = true;
				}

			},
			handleWithDraw() {
				this.withDrawForm.money = this.tableData.wallet.real_money
				this.withDrawForm.type = 0
				this.withDrawDialog = true;

			},
			confirmAddPerson() {

				this.loading = true
				this.$http.post(`/erp/v1/wallet/bankcard/bindPerson`, {
					...this.addForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success("绑定成功");
						this.addPersonDialog = false;
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			confirmAddEntry() {
				this.loading = true
				this.$http.post(`/erp/v1/wallet/bankcard/bindEnterprise`, {
					...this.addForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success("绑定成功");
						this.addEntryDialog = false;
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			confirmDelete() {
				this.loading = true
				this.$http.post(`/erp/v1/wallet/bankcard/delete`, {
					...this.withDrawForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success("提交成功");
						this.withDrawDialog = false;
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			confirmWithDraw() {
				this.loading = true
				this.$http.post(`/erp/v1/wallet/withdraw`, {
					...this.withDrawForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success("提交成功");
						this.withDrawDialog = false;
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`/erp/v1/wallet/bankcard`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},

		},
	}
</script>

<style lang="scss" scoped>
	table {
		font-size: 26px;
		min-width: 800px;
		line-height: 60px;
		text-align: left;

	}

	th,
	td {
		padding-left: 20px;
		border: 1px solid rgb(228, 230, 232);
		border-collapse: collapse;
	}

	.important {
		font-weight: 600;
	}
</style>